<template>
  <div class="content">
    <PageHeader
      :subtitle="$tc('app.ready_messages', 2)"
      title="Chat"
    />
    <section class="page-content container-fluid">
      <CardComponent>
        <template #header>
          {{ $tc('app.ready_messages', 2) }}
          <ActionsComponent>
            <li>
              <a class="text-primary" href="javascript:void(0)"
                 @click="$refs['create-macro'].show()">
                {{ $tc('macros.create') }}
                <span class="material-symbols-outlined">add_circle </span>
              </a>
            </li>
          </ActionsComponent>
        </template>
        <p>{{ $tc('macros.description') }}</p>
        <TableComponent :busy="loading" :fields="fields" :items="macros">
          <template #cell()="slotProps">
            <DropdownComponent no-caret variant="tertiary">
              <template #button>
                <span class="material-symbols-outlined">more_vert</span>
              </template>
              <a class="dropdown-item" href="javascript:void(0)" @click="editMacro(slotProps.item)">{{ $tc('app.edit') }}</a>
              <a class="dropdown-item" href="javascript:void(0)" @click="destroyMacro(slotProps.item.id)">{{ $tc('app.delete') }}</a>
            </DropdownComponent>
          </template>
        </TableComponent>
      </CardComponent>
    </section>
    <SidebarComponent ref="create-macro" :title="$tc('macros.create')" backdrop>
      <FormComponent ref="store-macro" @submit="storeMacro">
        <InputComponent v-model="form.name" :label="$tc('app.name')" required></InputComponent>
        <FormGroup :label="$tc('macros.availability')">
          <RadioComponent v-model="form.availability" name="availability" value="for_all">
            {{ $tc('app.for_all') }}
          </RadioComponent>
          <RadioComponent v-model="form.availability" name="availability" value="for_me">
            {{ $tc('app.for_me') }}
          </RadioComponent>
          <RadioComponent v-for="department in departments" :key="department.id"
                          v-model="form.availability"
                          :value="department.id" name="availability">{{ department.name }}
          </RadioComponent>
        </FormGroup>
        <TextAreaComponent v-model="form.text" :label="$tc('app.text')"
                           required></TextAreaComponent>
      </FormComponent>
      <template #footer>
        <div class="d-flex justify-content-between">
          <ButtonComponent variant="secondary" @click="$refs['create-macro'].hide()">{{ $t('app.cancel') }}</ButtonComponent>
          <ButtonComponent :loading="storing" @click="$refs['store-macro'].submit()">
            {{ $t('app.save') }}
          </ButtonComponent>
        </div>
      </template>
    </SidebarComponent>
    <SidebarComponent ref="edit-macro" :title="$tc('macros.edit')" backdrop>
      <FormComponent ref="update-macro" @submit="updateMacro">
        <InputComponent v-model="selectedMacro.name" :label="$tc('app.name')" required></InputComponent>
        <FormGroup :label="$tc('macros.availability')">
          <RadioComponent v-model="selectedMacro.availability" name="availability" value="for_all">
            {{ $tc('app.for_all') }}
          </RadioComponent>
          <RadioComponent v-model="selectedMacro.availability" name="availability" value="for_me">
            {{ $tc('app.for_me') }}
          </RadioComponent>
          <RadioComponent v-for="department in departments" :key="department.id"
                          v-model="selectedMacro.availability"
                          :value="department.id" name="availability">{{ department.name }}
          </RadioComponent>
        </FormGroup>
        <TextAreaComponent v-model="selectedMacro.text" :label="$tc('app.text')"
                           required></TextAreaComponent>
      </FormComponent>
      <template #footer>
        <div class="d-flex justify-content-between">
          <ButtonComponent variant="secondary" @click="$refs['edit-macro'].hide()">{{ $t('app.cancel') }}</ButtonComponent>
          <ButtonComponent :loading="storing" @click="$refs['update-macro'].submit()">
            {{ $t('app.save') }}
          </ButtonComponent>
        </div>
      </template>
    </SidebarComponent>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/PageHeader.vue';
import CardComponent from '@/components/CardComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';
import ActionsComponent from '@/components/ActionsComponent.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import TextAreaComponent from '@/components/form/TextAreaComponent.vue';
import RadioComponent from '@/components/form/RadioComponent.vue';
import FormGroup from '@/components/form/FormGroupComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import FormComponent from '@/components/form/FormComponent.vue';
import Swal from 'sweetalert2';

export default {
  name: 'ChatMacros',
  components: {
    FormComponent,
    ButtonComponent,
    FormGroup,
    RadioComponent,
    TextAreaComponent,
    InputComponent,
    SidebarComponent,
    ActionsComponent,
    DropdownComponent,
    TableComponent,
    CardComponent,
    PageHeader,
  },
  data() {
    return {
      loading: false,
      storing: false,
      fields: [
        { key: 'key', label: this.$tc('app.name') },
        { key: 'message' },
        { key: 'last_update', formatter: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss') },
        '',
      ],
      macros: [],
      departments: [],
      form: {
        name: '',
        availability: 'for_me',
        text: '',
      },
      selectedMacro: {},
    };
  },
  created() {
    this.fetchMacros();
    this.fetchDepartments();
  },
  methods: {
    fetchMacros() {
      this.loading = true;
      this.$store.dispatch('fetchChatMacros').then((response) => {
        this.macros = response;
      }).finally(() => {
        this.loading = false;
      });
    },
    fetchDepartments() {
      this.$store.dispatch('fetchChatDepartments').then((response) => {
        this.departments = response;
      });
    },
    storeMacro() {
      this.storing = true;
      this.$store.dispatch('storeChatMacros', this.form).then((response) => {
        this.$refs['create-macro'].hide();
        this.$toast.show({
          title: this.$t('app.success'),
          content: this.$t('macros.created'),
          type: 'success',
        });
        this.fetchMacros();
      }).catch((response) => {
        this.$toast.show({
          title: this.$t('app.error'),
          content: response,
          type: 'danger',
        });
      }).finally(() => {
        this.storing = false;
      });
    },
    editMacro(macro) {
      this.selectedMacro = macro;
      this.$refs['edit-macro'].show();
    },
    updateMacro() {
      this.storing = true;
      this.$store.dispatch('updateChatMacro', this.selectedMacro).then((response) => {
        this.$toast.show({
          title: this.$t('app.success'),
          content: this.$t('macros.updated'),
          type: 'success',
        });
        this.fetchMacros();
        this.$refs['edit-macro'].hide();
      }).catch((response) => {
        this.$toast.show({
          title: this.$t('app.error'),
          content: response,
          type: 'danger',
        });
      }).finally(() => {
        this.storing = false;
      });
    },
    destroyMacro(macroId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('macros.destroy'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.$store.dispatch('destroyChatMacro', macroId).then((response) => {
            this.fetchMacros();
          });
        }
      });
    },
    showFilter() {
      this.$refs.filter.toggle();
    },
    applyFilter() {
      this.form.filters = this.filter;
      this.$refs.filter.hide();
      this.$nextTick(() => this.reload());
    },
    cleanFilter() {
      this.filter = {
        agentId: '',
        channels: [],
        tabs: [],
        tags: [],
        protocol: '',
      };
    },
    reload() {
      this.$refs.view.fetch();
    },
    changeDate(date) {
      if (date) {
        this.form.date.gte = date[0];
        this.form.date.lte = moment(date[1]).format('YYYY-MM-DD 23:59:59');

        this.$nextTick(() => {
          this.reload();
        });
      }
    },
    exportData(format) {
      this.$refs.view.exportData(format);
    },
    copyLink() {
      navigator.clipboard.writeText(this.currentUrl).then(() => {
        this.$toast.show({
          title: 'Link Copiado',
          type: 'success',
        });
      });
    },
    showContact() {
      this.$refs.view.showContact();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
